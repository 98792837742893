<template>
  <div class="orderData">
    <div
      class="orderData__empty"
      v-if="showEmpty"
    >
      <el-empty description="暂时没有数据,请添加菜品~"></el-empty>
    </div>
    <!-- 主体的开始 -->
    <div
      class="orderData__body"
      v-if="!showEmpty"
    >
      <div class="orderData__body--item">
        <!-- <div class="orderData__body--item__number">
              订单编号:{{ getOrderSerialNum(item.orderSerialNum) }}
            </div> -->

        <!-- 食品列表 -->
        <div class="orderData__body--item__body">
          <el-scrollbar style="height: 100%" ref="scrollbar">
            <div class="orderData__body--item__body--list">
              <div v-for="item in orderLs" :key="item.phone">
                <cardItem
                  :foodItem="foodItem"
                  v-for="foodItem in item.ls"
                  :key="getKey(foodItem)"
                />
              </div>
            </div>
          </el-scrollbar>
        </div>
        <!-- 食品列表结束 -->
        <div class="orderData__body--item__data">
          <div class="orderData__body--item__data--item">
            <div class="orderData__body--item__data--item__left">总类</div>
            <div class="orderData__body--item__data--item__right">
              {{ foodNnmber }}
            </div>
          </div>
          <div class="orderData__body--item__data--item">
            <div class="orderData__body--item__data--item__left">合计</div>
            <div class="orderData__body--item__data--item__right features">
              ￥{{ money }}
            </div>
          </div>
        </div>
        <!-- 账目的结束 -->
        <div class="orderData__body--item__pay">
          <div class="orderData__body--item__pay--tips">付款方式</div>
          <div class="orderData__body--item__pay--type">
            <div
              @click="payType = 1"
              :class="{
                'orderData__body--item__pay--type__item': true,
                'orderData__body--item__pay--type__active': payType === 1,
              }"
            >
              <i class="el-icon-full-screen"></i>
              <span>支付码</span>
            </div>
            <div
              @click="payType = 2"
              :class="{
                'orderData__body--item__pay--type__item': true,
                'orderData__body--item__pay--type__active': payType === 2,
              }"
            >
              <img src="@/assets/cash.png" alt="" srcset="" />
              <span>现金</span>
            </div>
          </div>
          <!-- 底部按钮 -->
          <div class="orderData__body--item__bottom">
            <div
              :class="{
                'orderData__body--item__bottom--button': true,
                noPayType: !payType,
              }"
              @click="invoicing"
            >
              现在结账
            </div>
            <div
              class="orderData__body--item__bottom--button nowButton"
              @click="cash"
              v-if="shopInfo.isPayFirst !== 1"
            >
              下单
            </div>
          </div>
        </div>
        <!-- 付款方式的结束 -->
      </div>
    </div>
  </div>
</template>
<script>
import cardItem from '@/views/shop/components/cardItem/index.vue'
import orderInfo from '@/service/orderInfo.js'
import Decimal from 'decimal.js'
import Barcode from '@/utils/barcode2.js'
import { mapMutations, mapState } from 'vuex'
import aggregationPay from '@/utils/aggregationPay.js'
export default {
  components: {
    cardItem
  },
  data () {
    return {
      payType: '',
      businessTableMark: '',
      businessTableId: '',
      businessAreaId: '',
      persontNum: '',
      orderData: {},
      orderId: ''
    }
  },
  computed: {
    ...mapState({
      card: (store) => store.buyCard.card,
      shopInfo: (store) => store.login.shopInfo,
      checkout: store => store.buyCard.checkout,
      checkin: store => store.buyCard.checkin
    }),
    orderLs () {
      if (this.$refs.scrollbar) {
        const offsetTop = this.$refs.scrollbar.wrap.scrollHeight
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$refs.scrollbar.wrap.scrollTop = offsetTop
        console.log(offsetTop)
      }
      console.log(this.card)
      return this.card
    },
    foodNnmber () {
      return new Decimal(this.orderLs ? this.orderLs[0].ls.length : 0).toNumber()
    },
    money () {
      if (this.card.length > 0) {
        const reduceLs = []
        this.card.forEach((item) => {
          // 计算已经排除的人
          if (!this.checkout.includes(item.phone)) {
            reduceLs.push(...item.ls)
          }
        })
        return reduceLs.reduce((pre, item) => {
          const count = new Decimal(item.count || 0)
          const foodConcessionalPrice = new Decimal(
            item.foodConcessionalPrice || 0
          ).mul(new Decimal(count))
          return new Decimal(pre).add(foodConcessionalPrice).toNumber()
        }, 0)
      } else {
        return 0
      }
    },
    // 是否显示空状态
    showEmpty () {
      return !this.orderLs || this.orderLs.length === 0 || this.orderLs[0].ls.length === 0
    }
  },
  created () {
    this.businessTableMark = this.$route.query.businessTableMark
    this.businessTableId = this.$route.query.businessTableId
    this.businessAreaId = this.$route.query.businessAreaId
    this.persontNum = this.$route.query.persontNum
    this.orderId = this.$route.query.orderId
    // 存在订单id,加餐操作
    // if (this.orderId) {
    //   // 查询订单详情
    //   this.getOrderInfo()
    // }
  },
  methods: {
    ...mapMutations({
      resetCard: 'buyCard/resetCard'
    }),
    // 查询订单详情
    // async getOrderInfo () {
    //   const res = await orderInfo.getOrderById(this.orderId)
    //   const orderFoodDtoList = res.data.orderFoodDtoList.map((item) => {
    //     return {
    //       ...item,
    //       isNew: 0
    //     }
    //   })
    //   const orderData = {
    //     ...res.data,
    //     orderFoodDtoList
    //   }
    //   delete orderData.orderFoodListStr
    //   this.orderData = orderData
    //   return orderData
    // },
    getOrderSerialNum (orderSerialNum) {
      if (!orderSerialNum) {
        return
      }
      return orderSerialNum.substr(-3, 3)
    },
    cancelOrder (item) {
      this.$emit('cancelOrder', item)
    },
    // 稳定的key,确保列表不强制刷新
    getKey (item) {
      if (!item) {
        return
      }
      const obj = { ...item }
      if (obj.foodProp) {
        obj.foodProp = JSON.parse(obj.foodProp)
      }
      if (!obj.foodProp || obj.length === 0) {
        return obj.id
      } else {
        return obj.id + item.foodProp
      }
    },
    // 创建订单
    async createOrder () {
      const cardLs = this.card.filter((item) =>
        this.checkin.includes(item.phone)
      )

      const orderFoodDtoList = cardLs.map((item) => {
        const foodDtoList = item.ls.map((foodDto) => {
          const foodProp = typeof foodDto.foodProp === 'string' ? JSON.parse(foodDto.foodProp) : foodDto.foodProp
          const ls = []
          for (const i in foodProp) {
            const obj = {}
            obj.name = i
            obj.specsList = [foodProp[i]]
            ls.push(obj)
          }
          return {
            foodId: foodDto.id,
            foodNum: foodDto.count,
            foodName: foodDto.foodName,
            foodOriginalPrice: foodDto.foodOriginalPrice,
            foodActualPrice: foodDto.foodConcessionalPrice,
            foodPropDtoList: ls,
            foodImgUrl: foodDto.foodImgUrl,
            foodTypeId: foodDto.foodTypeId,
            businessAreaId: foodDto.businessAreaId,
            isNew: 1
          }
        })
        return { phone: item.phone, foodDtoList }
      })

      // 获取口袋中全部的商品
      const goods = []
      console.log('orderFoodDtoList', orderFoodDtoList)
      orderFoodDtoList.forEach((item) => {
        goods.push(...item.foodDtoList)
      })

      // 计算实际价格
      const actualPrice = goods.reduce((pre, item) => {
        const count = new Decimal(item.foodNum)
        const foodActualPrice = new Decimal(item.foodActualPrice).mul(
          new Decimal(count)
        )
        return new Decimal(pre).add(foodActualPrice).toNumber().toFixed(2)
      }, 0)
      // 计算原来的价格
      const originalPrice = goods.reduce((pre, item) => {
        const count = new Decimal(item.foodNum)
        const foodOriginalPrice = new Decimal(item.foodOriginalPrice).mul(
          new Decimal(count)
        )
        return new Decimal(pre).add(foodOriginalPrice).toNumber().toFixed(2)
      }, 0)
      const data = {
        actualPrice: actualPrice,
        businessId: this.shopInfo.id,
        businessTableMark: this.businessTableMark,
        businessTableId: this.businessTableId,
        businessAreaId: this.businessAreaId,
        orderFoodDtoList,
        remarks: this.remark,
        repastUserPhones: this.checkin.join(','),
        repastUserNum: this.persontNum,
        originalPrice: originalPrice
      }
      const res = await orderInfo.orderInfoSave(data)
      return res.data
    },
    async invoicing () {
      if (!this.payType) {
        this.$message({
          message: '请选择支付方式',
          type: 'warning'
        })
        return
      }
      // 微信支付
      if (this.payType === 1) {
        // const orderId = await this.createOrder()
        // 安卓手机
        if (navigator.userAgent.indexOf('Html5Plus') > -1) {
          console.log(!!this.orderId)
          // eslint-disable-next-line no-unused-vars
          const barCodeDom = new Barcode({
            successCD: (authCode) =>
              aggregationPay({
                authCode,
                createOrder: true,
                modifyOrder: !!this.orderId,
                businessTableMark: this.businessTableMark,
                businessTableId: this.businessTableId,
                businessAreaId: this.businessAreaId,
                persontNum: this.persontNum,
                orderId: this.orderId,
                getOrderInfo: this.getOrderInfo,
                createOrderCD: (orderId) => {
                  barCodeDom.closeBarcodeComponent()
                  this.printOrder({
                    orderId: orderId,
                    voice: 2
                  })
                },
                modifyOrderCD: () => {
                  barCodeDom.closeBarcodeComponent()
                  this.$router.back()
                },
                fail: () => {
                  barCodeDom.closeBarcodeComponent()
                  this.$notify.error({
                    title: '错误',
                    message: '支付失败'
                  })
                }
              })
          })
        } else {
          const scannerVideo = this.$scannerVideo({
            successCD: (authCode) =>
              aggregationPay({
                authCode,
                createOrder: true,
                modifyOrder: !!this.orderId,
                businessTableMark: this.businessTableMark,
                businessTableId: this.businessTableId,
                businessAreaId: this.businessAreaId,
                persontNum: this.persontNum,
                orderId: this.orderId,
                getOrderInfo: this.getOrderInfo,
                scannerVideo: scannerVideo,
                createOrderCD: (orderId) => {
                  scannerVideo.cancelPay()
                  this.printOrder({
                    orderId: orderId,
                    voice: 2
                  })
                },
                modifyOrderCD: () => {
                  scannerVideo.cancelPay()
                  this.$router.back()
                }
              })
          })
        }
      } else {
        const confirm = await this.$confirm(
          '请确保用户已经使用现金支付?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        if (confirm === 'confirm') {
          // 生成订单
          const orderId = await this.createOrder()
          const outTradeNo = Math.floor(Math.random() * 9999) * Date.now() + ''
          const res = await orderInfo.editOrderPayType({
            id: orderId,
            outTradeNo: outTradeNo,
            payType: 4
          })
          // 修改订单支付方式成功后修改订单支付状态为已支付
          if (res.success) {
            const statusRes = await orderInfo.changeOrderStatus({
              id: orderId,
              orderStatus: 1
            })
            this.resetCard()
            // 支付状态修改成功，打印小票
            if (statusRes.success) {
              this.printOrder({
                orderId: orderId,
                voice: 2
              })
            }
          }
        }
      }
    },
    async cash () {
      const confirm = await this.$confirm('是否确定下单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })

      if (confirm === 'confirm') {
        // 生成订单
        const orderId = await this.createOrder()

        const outTradeNo = Math.floor(Math.random() * 9999) * Date.now() + ''
        const res = await orderInfo.editOrderPayType({
          id: orderId,
          outTradeNo: outTradeNo,
          payType: 4
        })
        // 修改订单支付方式成功后修改订单支付状态为未支付
        if (res.success) {
          const statusRes = await orderInfo.changeOrderStatus({
            id: orderId,
            orderStatus: 0
          })

          this.resetCard()
          // 支付状态修改成功，打印小票
          if (statusRes.success) {
            this.printOrder({
              orderId: orderId,
              voice: 2
            })
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.orderData {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  &__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__body {
    width: 100%;
    height: 100%;
    &--item {
      width: 100%;
      height: 100%;
      padding: 31px 36px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &__number {
        color: #000000;
        font-size: 20px;
        margin-bottom: 22px;
      }
      &__body {
        flex: 1;
        min-height: 0px;
        overflow: hidden;
        border-bottom: 1px solid #dfdfdf;
        box-sizing: border-box;
        &--list {
          overflow: hidden;
          &__tag {
            margin-bottom: 10px;
          }
        }
      }
      &__data {
        padding-top: 14px;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #dfdfdf;
        &--item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 19px;
          &__left {
            color: #000000;
            font-weight: 400;
            font-size: 20px;
          }
          &__right {
            color: #000000;
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
      &__pay {
        padding: 20px 0px;
        box-sizing: border-box;
        &--tips {
          color: #000000;
          font-weight: 400;
          font-size: 20px;
        }
        &--type {
          display: flex;
          margin-top: 20px;
          align-items: center;
          &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 68px;
            width: 144px;
            height: 52px;
            background: #f7f7fc;
            border-radius: 8px;
            box-sizing: border-box;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
              margin-right: 16px;
            }
            span {
              color: #000000;
              font-weight: 400;
              font-size: 14px;
            }
          }
          &__active {
            border: 1px solid #ffbb12;
          }
        }
      }
      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 30px;
        &--button {
          cursor: pointer;
          background: #ffbb12;
          opacity: 0.89;
          border-radius: 8px;
          flex: 1;
          min-width: 0;
          height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000000;
          font-weight: 900;
          font-size: 16px;
        }
        .nowButton {
          width: 175px !important;
          margin-left: 33px;
          flex: none !important;
        }
      }
    }
    ::v-deep .el-carousel {
      width: 100%;
      height: 100%;
    }
    ::v-deep .el-carousel__container {
      width: 100%;
      height: 100%;
    }
  }
}
::v-deep .el-carousel__indicator.is-active button {
  background: #ffbb12 !important;
}
::v-deep .el-carousel__indicator button {
  background: #d9d9d9 !important;
}
::v-deep .el-scrollbar {
  overflow-y: hidden !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto !important;
}
::v-deep .is-horizontal {
  display: none;
}
.features {
  color: #f05454;
}
.noPayType {
  background: #ffbb12 !important;
  opacity: 0.5 !important;
}
.el-icon-full-screen {
  margin-right: 16px;
  color: #ffbb12;
  font-size: 25px;
  font-weight: 700;
}
</style>
