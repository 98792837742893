var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orderData"},[(_vm.showEmpty)?_c('div',{staticClass:"orderData__empty"},[_c('el-empty',{attrs:{"description":"暂时没有数据,请添加菜品~"}})],1):_vm._e(),(!_vm.showEmpty)?_c('div',{staticClass:"orderData__body"},[_c('div',{staticClass:"orderData__body--item"},[_c('div',{staticClass:"orderData__body--item__body"},[_c('el-scrollbar',{ref:"scrollbar",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"orderData__body--item__body--list"},_vm._l((_vm.orderLs),function(item){return _c('div',{key:item.phone},_vm._l((item.ls),function(foodItem){return _c('cardItem',{key:_vm.getKey(foodItem),attrs:{"foodItem":foodItem}})}),1)}),0)])],1),_c('div',{staticClass:"orderData__body--item__data"},[_c('div',{staticClass:"orderData__body--item__data--item"},[_c('div',{staticClass:"orderData__body--item__data--item__left"},[_vm._v("总类")]),_c('div',{staticClass:"orderData__body--item__data--item__right"},[_vm._v(" "+_vm._s(_vm.foodNnmber)+" ")])]),_c('div',{staticClass:"orderData__body--item__data--item"},[_c('div',{staticClass:"orderData__body--item__data--item__left"},[_vm._v("合计")]),_c('div',{staticClass:"orderData__body--item__data--item__right features"},[_vm._v(" ￥"+_vm._s(_vm.money)+" ")])])]),_c('div',{staticClass:"orderData__body--item__pay"},[_c('div',{staticClass:"orderData__body--item__pay--tips"},[_vm._v("付款方式")]),_c('div',{staticClass:"orderData__body--item__pay--type"},[_c('div',{class:{
              'orderData__body--item__pay--type__item': true,
              'orderData__body--item__pay--type__active': _vm.payType === 1,
            },on:{"click":function($event){_vm.payType = 1}}},[_c('i',{staticClass:"el-icon-full-screen"}),_c('span',[_vm._v("支付码")])]),_c('div',{class:{
              'orderData__body--item__pay--type__item': true,
              'orderData__body--item__pay--type__active': _vm.payType === 2,
            },on:{"click":function($event){_vm.payType = 2}}},[_c('img',{attrs:{"src":require("@/assets/cash.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("现金")])])]),_c('div',{staticClass:"orderData__body--item__bottom"},[_c('div',{class:{
              'orderData__body--item__bottom--button': true,
              noPayType: !_vm.payType,
            },on:{"click":_vm.invoicing}},[_vm._v(" 现在结账 ")]),(_vm.shopInfo.isPayFirst !== 1)?_c('div',{staticClass:"orderData__body--item__bottom--button nowButton",on:{"click":_vm.cash}},[_vm._v(" 下单 ")]):_vm._e()])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }