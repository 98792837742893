<template>
  <div class="foodItem">
    <div class="foodItem__left">
      <div class="foodItem__left--img">
        <img :src="imgUrl + foodItem.foodImgUrl" alt="" srcset="" />
      </div>
      <div class="foodItem__left--data">
        <div class="foodItem__left--data__name">
          {{ foodItem.foodName }}
        </div>
        <div class="foodItem__left--data__name">
          {{ getProps(foodItem.foodProp || foodItem.foodPropDtoList) }}
        </div>
        <div class="foodItem__left--data__name">
          ￥{{ foodItem.foodConcessionalPrice || foodItem.foodActualPrice }}
        </div>
      </div>
    </div>
    <div class="foodItem__right">
      <div
        :class="{
          'foodItem__bottom--buy': true,
          oldFood: oldFood,
        }"
      >
        <i class="el-icon-remove" @click="reduce"></i>
        <div class="foodValue">{{ foodItem.count || foodItem.foodNum }}</div>
        <i class="el-icon-circle-plus" @click="setCarAnmation"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  props: {
    foodItem: {
      type: Object,
      default: () => {}
    },
    // 是否旧餐单
    oldFood: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  methods: {
    ...mapMutations({
      setCard: 'buyCard/setCard',
      reduceCard: 'buyCard/reduceCard'
    }),
    getProps (props) {
      console.log(props)
      if (!props || props === null) {
        return ''
      }
      if (this.oldFood) {
        const str = Object.values(props).map((item) => item.name)
        return str.join(',')
      }
      const obj = JSON.parse(props)
      const str = Object.values(obj).map((item) => item.name)
      return str.join(',')
    },
    // 减少购物车
    reduce () {
      // 旧餐单不可修改
      if (this.oldFood) {
        this.$message({
          message: '不可操作',
          type: 'warning'
        })
        return
      }
      if (!this.foodItem.foodProp || this.foodItem.foodProp.length === 0) {
        this.reduceCard({
          shopId: this.shopInfo.id,
          ...this.foodItem
        })
      } else {
        this.reduceCard({
          shopId: this.shopInfo.id,
          ...this.foodItem,
          foodProp: this.foodItem.foodProp,
          foodConcessionalPrice: this.foodItem.foodConcessionalPrice,
          foodOriginalPrice: this.foodItem.foodOriginalPrice
        })
      }
    },
    setCarAnmation () {
      if (this.oldFood) {
        this.$message({
          message: '不可操作',
          type: 'warning'
        })
        return
      }
      if (!this.foodItem.foodProp || this.foodItem.foodProp.length === 0) {
        this.setCard({
          shopId: this.shopInfo.id,
          ...this.foodItem
        })
      } else {
        this.setCard({
          shopId: this.shopInfo.id,
          ...this.foodItem,
          foodProp: this.foodItem.foodProp,
          foodConcessionalPrice: this.foodItem.foodConcessionalPrice,
          foodOriginalPrice: this.foodItem.foodOriginalPrice
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.foodItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  &__left {
    display: flex;
    align-items: center;
    &--img {
      width: 98px;
      height: 98px;
      border-radius: 8px;
      margin-right: 22px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    &--data {
      height: 98px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__name {
        color: #000000;
        font-weight: 400;
        font-size: 20px;
      }
    }
  }
  &__right {
    color: #000000;
    font-size: 20px;
    margin-right: 30px;
  }
}

.foodItem__bottom--buy {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .foodValue {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
  }
  i {
    color: #ffbb12;
    font-size: 20px;
    cursor: pointer;
  }
}
.oldFood {
  i {
    color: black;
  }
}
</style>
